import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { USER_URL } from '@Env/environment';
import { Observable } from 'rxjs';
import { DocumentsData } from '@Mesh/core/models/document';
import { objectToParams } from '../../../../shared/helpers/object.helpers';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  readonly baseUrl = `${USER_URL}/document`;

  constructor(private http: HttpClient) {}

  getDocuments(searchParams: Partial<DocRequestParams>): Observable<DocumentsData> {
    const params = objectToParams(searchParams);
    return this.http.get<DocumentsData>(`${this.baseUrl}`, { params });
  }
}

export interface DocRequestParams {
  documentType: string[];
  clientId: number[];
  iqReportId: number;
  startDate: string;
  endDate: string;
  size: number;
  page: number;
  sort?: string;
  direction?: string;
}
